<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar dark color="primary" dense flat class="draggable_selector" slot="header">
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn text small @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text class="pt-5">
            <template v-for="field in usual_fields">
              <base-field
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  action="create"
                  @change="usualFieldDataChanged"/>
            </template>
            <template v-for="field in custom_fields">
              <base-field
                  :key="field.name"
                  :value="record.custom_fields[field.name]"
                  :field="field"
                  @change="customFieldDataChanged"/>
            </template>
            <v-select
                v-if="model_type && (model_type === 'Exam' || model_type === 'Candidate')"
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('attach_to')"
                v-model="selected_model"
                :items="getAttachToModels"/>
            <v-select
                v-if="selected_model === 'Assessor'"
                :class="'ma-0 pt-'+$store.getters.lineheight"
                v-model="record.assessor_ids"
                clearable
                deletable-chips
                small-chips
                multiple
                outlined
                item-value="id"
                item-text="name"
                :items="assessors"
                :menu-props="{ bottom: true, offsetY: true }"/>
            <v-select
                v-if="selected_model === 'Candidate'"
                :class="'ma-0 pt-'+$store.getters.lineheight"
                v-model="record.candidate_ids"
                clearable
                deletable-chips
                small-chips
                multiple
                outlined
                item-value="id"
                item-text="name"
                :items="candidates"
                :menu-props="{ bottom: true, offsetY: true }"/>
            <v-select
                v-if="selected_model === 'Exam'"
                :class="'ma-0 pt-'+$store.getters.lineheight"
                v-model="record.exam_ids"
                clearable
                deletable-chips
                small-chips
                multiple
                outlined
                item-value="id"
                item-text="name"
                :items="exams"
                :menu-props="{ bottom: true, offsetY: true }"/>
            <v-select
                v-if="selected_model === 'Company'"
                :class="'ma-0 pt-'+$store.getters.lineheight"
                v-model="record.company_ids"
                clearable
                deletable-chips
                small-chips
                multiple
                outlined
                item-value="id"
                item-text="name"
                :items="companies"
                :menu-props="{ bottom: true, offsetY: true }"/>
            <v-select
                v-if="selected_model === 'Dossier'"
                :class="'ma-0 pt-'+$store.getters.lineheight"
                v-model="record.dossier_ids"
                clearable
                deletable-chips
                small-chips
                multiple
                outlined
                item-value="id"
                item-text="name"
                :items="dossiers"
                :menu-props="{ bottom: true, offsetY: true }"/>
            <v-checkbox :label="$store.getters.translate('generate_document')" v-model="record.generate_document" dense hide-details/>
            <template v-if="record.generate_document">
              <div class="pa-5 mt-3">
                <v-select
                    :label="$store.getters.translate('template')"
                    v-model="record.template_id"
                    validate-on-blur
                    :items="$lodash.sortBy(templates, 'name')"
                    item-value="id"
                    item-text="name"
                    @change="setDocumentName"
                    chips/>
                <v-text-field
                    ref="document_name"
                    :label="$store.getters.translate('document_name')"
                    v-model="record.document_name"
                    counter/>
              </div>
            </template>
            <v-row>
              <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                <v-file-input
                  class="mt-3"
                  :label="$store.getters.translate('files')"
                  placeholder="Drop file to upload"
                  show-size
                  v-model="record.files"
                  multiple/>
              </v-col>
            </v-row>
            <base-tags v-if="tags_required" v-model="record.tags" :module_name="module_name"/>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../components/commonComponents/BaseModal";
import helpFunctions from "../../plugins/helpFunctions";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["module_name", "model_type", "model_id", "assessors", "candidates", "companies", "dossiers", "exams", "modal_name"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("note"),
        name: "notes",
        model: "note",
      },
      loading: false,
      record: {
        files: [],
      },
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      templates: [],
      selected_model: "",
    };
  },
  created() {
    this.record = helpFunctions.default_note;
    this.getFields();
    this.getTemplates();
  },
  methods: {
    ...helpFunctions.modal_functions,
    save() {
      if(!this.loading) {
        let error = false;
        this.usual_fields.forEach((field) => {
          if (field.mandatory && field.field_type !== 'boolean' && (!this.record[field.name] || this.record[field.name] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
            error = true;
          }
        });
        if (!error) {
          this.loading = true;
          const formData = new FormData();
          if (this.record.files) {
            this.record.files.forEach((file) => {
              formData.append("attachment[]", file);
            });
          }
          formData.append("title", this.record.title);
          formData.append("content", this.record.content);
          formData.append("type", "");
          formData.append("visible_candidate", this.record.visible_candidate);
          formData.append("editable_candidate", this.record.editable_candidate);
          formData.append("visible_contact", this.record.visible_contact);
          formData.append("editable_contact", this.record.editable_contact);
          formData.append("visible_assessor", this.record.visible_assessor);
          formData.append("editable_assessor", this.record.editable_assessor);
          formData.append("request_fulfilled", this.record.request_fulfilled);
          formData.append("is_request", this.record.is_request);
          if (this.module_name && this.model_id) {
            formData.append("model", this.$models[this.module_name]);
            formData.append("model_id", this.model_id);
          }
          formData.append("assessor_ids", this.record.assessor_ids);
          formData.append("candidate_ids", this.record.candidate_ids);
          formData.append("exam_ids", this.record.exam_ids);
          formData.append("company_ids", this.record.company_ids);
          formData.append("dossier_ids", this.record.dossier_ids);
          formData.append("tags", JSON.stringify(this.record.tags));
          formData.append("generate_document", this.record.generate_document);
          if (this.record.template_id) {
            formData.append("template_id", this.record.template_id);
          }
          formData.append("document_name", this.record.document_name);

          this.$http
              .post(this.$store.getters.appUrl + "v2/" + this.page.name, formData, {
                headers: {"Content-Type": "multipart/form-data"},
              })
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.$emit("refresh");
                  this.reset();
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
        }
      }
    },
    setDocumentName() {
      let template = this.templates.find((template) => template.id == this.record.template_id);
      if (template) {
        this.record.document_name = template.name;
        this.$refs.document_name.focus();
      }
    },
    addDropFile(e) {
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        this.record.files.push(e.dataTransfer.files[i]);
      }
    },
    async getTemplates() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/templates")
          .then((response) => {
            this.templates = [];
            response.data.data.forEach((option) => {
              this.templates.push({
                id: option.id,
                name: option.name,
              });
            });
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
    reset() {
      this.record = {
        title: "",
        content: "",
        category: "",
        files: [],
        visible_candidate: false,
        editable_candidate: false,
        visible_contact: false,
        editable_contact: false,
        visible_assessor: false,
        editable_assessor: false,
        tags: [],
        template_id: null,
        document_name: null,
        generate_document: false,
        assessor_ids: [],
        candidate_ids: [],
        exam_ids: [],
        company_ids: [],
        dossier_ids: [],
        dossiers: [],
        candidates: [],
        exams: [],
        assessors: [],
        schemes: [],
        employees: [],
        contacts: [],
        companies: [],
        custom_fields: {},
      };
      this.closeModal(this.modal_name);
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_on_creation=true&model_type=" + this.page.model)
          .then((response) => {
            this.custom_fields = [];
            this.usual_fields = [];
            response.data.data.forEach((field) => {
              if (field.custom_field == 1) {
                this.custom_fields.push(field);
              }
              else if(field.name === 'tags') {
                this.tags_required = true;
              }
              else {
                if(field.field_type === 'select_single' || field.field_type === 'select_multiple') {
                  for (let i = 0; i < field.available_options.length; i++) {
                    field.available_options[i] = {
                      text: this.$store.getters.translate(field.available_options[i]),
                      value: field.available_options[i],
                    };
                  }
                }
                this.usual_fields.push(field);
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
  },
  computed: {
    getAttachToModels() {
      var models = [{
          value: "Assessor",
          text: this.$store.getters.translate("assessors"),
        },
        {
          value: "Company",
          text: this.$store.getters.translate("companies"),
        },
        {
          value: "Dossier",
          text: this.$store.getters.translate("dossiers"),
        }];
      if (this.model_type === "Exam") {
        models.push({
          value: "Candidate",
          text: this.$store.getters.translate("candidates"),
        });
      } else if (this.model_type === "Candidate") {
        models.push({
          value: "Exam",
          text: this.$store.getters.translate("exams"),
        });
      }
      return this.$lodash.sortBy(models, "text");
    },
  },
};
</script>
